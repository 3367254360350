import {
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest
} from "@angular/common/http";
import { catchError, retry } from "rxjs";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { environment } from "../../environments/environment";

export const authInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
) => {
    let auth = inject(AuthService);
    let token = localStorage.getItem("access_token");

    const newReq = token
        ? req.clone({
              setHeaders: {
                  Authorization: `Bearer ${token}`
              }
          })
        : req.clone({});

    if (
        newReq.url.startsWith(environment.auth.tokenUrl) ||
        newReq.url.startsWith(environment.auth.loginUrl)
    ) {
        return next(req);
    }

    return next(newReq).pipe(
        catchError((error) => auth.authErrorOnApi(error)),
        retry(1)
    );
};
