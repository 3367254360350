export const environment = {
    apiPath: "/dmp",
    production: false,
    noAuth: false,
    userId: "1",
    auth: {
        loginUrl: "https://sts-dev.fiat.com/adfs/oauth2/authorize",
        tokenUrl: "https://sts-dev.fiat.com/adfs/oauth2/token",
        appName: "DMP",
        client_id: "3f7a5705-83ac-490d-b9cc-49986a90284e",
        redirect_uri: "/redirect"
    }
};
