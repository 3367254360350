import { bootstrapApplication } from "@angular/platform-browser";
import { appConfig } from "./app/app.config";
import { AppComponent } from "./app/app.component";
import "@angular/common/locales/global/it";

import CharDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";

Chart.register(CharDataLabels);

bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
);
