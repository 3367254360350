import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

export function mockUserInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
    const newReq = req.clone({
        headers:
            environment.noAuth && !req.headers.get("userId")
                ? req.headers.append("userId", environment.userId)
                : req.headers
    });
    return next(newReq);
}
