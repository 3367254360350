import { UserResponse } from "../api/v1";

export enum UserRole {
    DEALER_READER = "DEALER_READER",
    DEALER_OPERATOR = "DEALER_OPERATOR",
    MARKENTING_MANAGER = "MARKENTING_MANAGER",
    SUPER_MARKET_MANAGER = "SUPER_MARKET_MANAGER",
    HQ = "HQ",
    ADMIN = "ADMIN"
}

export type User = {
    roles: UserRole[];
    infos: UserResponse;
};
