import { TranslocoService } from "@ngneat/transloco";
import { PrimeNGConfig } from "primeng/api";
import { switchMap, tap } from "rxjs";

export function primengTranslationFactory(
    transloco: TranslocoService,
    primengConfig: PrimeNGConfig
) {
    let lang = navigator?.language?.split("-")?.[0] ?? "en";
    transloco.setActiveLang(
        transloco.getAvailableLangs().includes(lang as any) ? lang : "en"
    );

    transloco.langChanges$
        .pipe(
            switchMap((lang) => transloco.load(lang)),
            tap((res) => primengConfig.setTranslation(res["primeng"]))
        )
        .subscribe();
}
