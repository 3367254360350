import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { inject } from "@angular/core";
import { distinctUntilChanged, filter, switchMap, tap } from "rxjs";

export const authGuard: CanActivateFn = () => {
    const auth = inject(AuthService);
    const router = inject(Router);
    return auth.loginCompleted$.pipe(
        filter((el) => !!el),
        distinctUntilChanged(),
        switchMap(() => auth.isLoggedIn$),
        tap((logged) => {
            if (!logged) router.navigate(["unauthorized"]);
        })
    );
};
